<div class="googleReview mt-4" *ngIf="storDetails && storDetails.reviews.length > 0">
    <div class="grHead">
        <h2 class="boldLIght towLineHead">
            <strong><span class="f-32">Google</span></strong> Business Reviews
        </h2>
    </div>

    <div class="googleReviewSlide">
        <div class="grList" *ngIf="storDetails && storDetails.reviews.length > 0">
            <div class="grItem" *ngFor="let review of storDetails.reviews">
                <div class="giHead">
                    <span class="giLeft">
                        <h3 style="margin-bottom: auto;">{{review.display_Name}}</h3>
                        <i>Posted on : {{review.crt_Dt | date: 'dd/MM/yyyy hh:mm a'}}</i>
                    </span>
                    <span class="starRating">{{review.star_Rating}}</span>
                </div>
                <div class="reviws">
                    <p>{{review.comment}}</p>
                </div>
            </div>
        </div>
    </div>
</div>