import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-review',
  templateUrl: './google-review.component.html',
  styleUrls: ['./google-review.component.scss']
})
export class GoogleReviewComponent implements OnInit {
  
  @Input() storDetails: any = {};
  constructor() { }

  ngOnInit(): void {
  }

  count(){
   let total = Math.round(this.storDetails.reviews.length / 4) + 1;
   let arry = [];
   for (let index = 0; index < total; index++) {
    arry.push(1+index)
   }
   return arry;
  }

}
