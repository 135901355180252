import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { BannerComponent } from './components/banner/banner.component';
import { SearchComponent } from './components/search/search.component';
import { SectionComponent } from './components/section/section.component';
import { MapComponent } from './components/map/map.component';
import { StoreListComponent } from './components/store-list/store-list.component';
import { StoreInfoComponent } from './components/store-info/store-info.component';
import { StoreGalleryComponent } from './components/store-gallery/store-gallery.component';
import { BusinessReviewsComponent } from './components/business-reviews/business-reviews.component';
import { ImagesComponent } from './components/images/images.component';
import { FaqComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { StoreSiteComponent } from './components/store-site/store-site.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogoComponent } from './components/logo/logo.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { ConNumComponent } from './components/con-num/con-num.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextSpliteByHyphenPipe } from './pipe/text-splite-by-hyphen-pipe.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RatingModule } from 'ng-starrating';
import { GlobalObjectService } from 'src/global-object.service';
import { GoogleMapsModule } from '@angular/google-maps'
declare var $: any;
import { LoadingComponent } from './components/loading/loading.component';
import { OnlynumberDirective } from './directive/onlynumer.directive';
import { StoreRouterComponent } from './store-router/store-router.component'
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NewLaunchComponent } from './components/new-launch/new-launch.component';
import { SafePipe } from './safe.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { GoogleReviewComponent } from './google-review/google-review.component';
import { EnquiryFormComponent } from './enquiry-form/enquiry-form.component';
import { StoreLocatorFormComponent } from './store-locator-form/store-locator-form.component';
import { ShareButtonDirective } from './share-button.directive';
import { FocusDirective } from './directive/focusOn.directive';
import { CheckBoxRefDirective } from './directive/checkbox.directive';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SocialMediaComponent,
    BannerComponent,
    SearchComponent,
    SectionComponent,
    MapComponent,
    StoreListComponent,
    StoreInfoComponent,
    StoreGalleryComponent,
    BusinessReviewsComponent,
    ImagesComponent,
    FaqComponent,
    HomeComponent,
    StoreSiteComponent,
    LogoComponent,
    NavItemComponent,
    ConNumComponent,
    TextSpliteByHyphenPipe,
    LoadingComponent,
    OnlynumberDirective,
    StoreRouterComponent,
    PageNotFoundComponent,
    NewLaunchComponent,
    SafePipe,
    HomeComponent,
    StarRatingComponent,
    GoogleReviewComponent,
    EnquiryFormComponent,
    StoreLocatorFormComponent,
    ShareButtonDirective,
    FocusDirective,
    CheckBoxRefDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RatingModule,
    GoogleMapsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule
  ],
  providers: [GlobalObjectService,
    { provide: 'isBrowser', useValue: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
