import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  @Input() storDetails: any = {};

  constructor() { }

  ngOnInit(): void {
  }


  getRating() : number{
   return this.storDetails.reviews[0].averate_rating
  }
}
