import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ApiService } from "src/app/api.service";
import { AppComponent } from "src/app/app.component";
import { SharedInfoService } from "src/app/sharedInfo.service";
declare var $: any;
declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}

@Component({
  selector: "app-section",
  templateUrl: "./section.component.html",
  styleUrls: ["./section.component.scss"],
})
export class SectionComponent implements OnInit, AfterViewInit {


  private player: any;
  @Input() sectionInput: any;
  mainSection: any;
  YTShown: boolean = false;
  banners: any = {};
  bannarVideos: any = {};
  offerBannerDesk: any;
  reviews: any[] = [];
  bannerSection: any[] = [];
  isAutoplay: boolean = true;
  private sliderInitialized = false;

  constructor(
    private sharedInfoService: SharedInfoService,
    private breakpointObserver: BreakpointObserver,
    private santizer: DomSanitizer,
    public app: AppComponent,
    private apiService: ApiService
  ) {
 
  }

  ngOnInit(): void {
    this.apiService.reviewsObservable.subscribe((data) => {
      this.reviews = data;
      this.slickReviews();
    });
    this.sharedInfoService.currentSocialMedia.subscribe((data: any) => {
      this.mainSection = data;
    });

    let baseURL = "";

    if (
      window.location.hostname === "127.0.0.1" ||
      window.location.hostname === ""
    ) {
      baseURL = "https://dell-dev.hashconnect.in";
    }

    $.ajax({
      url: baseURL + "/api/tenant/content/config?tenantCode=STORE-LOCATOR",
      type: "GET",
      dataType: "json",
      secure: true,
      headers: {
        "Content-Type": "application/json",
      },
      success: (response: any) => {
        console.log(response);
        this.banners = [];
        let content = JSON.parse(response.content.content);
        if (content.footer) this.apiService.footer.next(content.footer);
        if (content.products) this.apiService.products.next(content.products);
        if (content.faq) this.apiService.faq.next(content.faq);

        if (content.storeLocator)  this.banners = content.storeLocator;
        if(this.banners.offerBannerDesk){
          this.bannerSection = this.banners.offerBannerDesk.sort(
            (a: any, b: any) => a.order - b.order
          );
        }
      
        this.bannarVideos = this.banners.videos
          .filter((t: any) => t.isDisplay === true)
          .sort((a: any, b: any) => a.order - b.order);
          this.videosSlider();
        setTimeout(() => {
          this.reviewBanner();
        }, 500);
      },
    });
  }

  videosSlider() {
    $(document).ready( () => {
      setTimeout(() => {
        var offerSlider = $(".videoSlider");
        if (offerSlider.hasClass("slick-initialized")) {
          $(".videoSlider").slick("unslick");
        }
        if (offerSlider != null) {
          offerSlider.slick({
            centerMode: false,
            centerPadding: "0px",
            dots: false,
            arrows: false,
            infinite: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            initialSlide: 0,
            responsive: [
              {
                breakpoint: 1250,
                settings: {
                  centerMode: false,
                  centerPadding: "0px",
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 969,
                settings: {
                  centerMode: false,
                  centerPadding: "0px",
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  centerMode: false,
                  centerPadding: "0px",
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  centerMode: false,
                  centerPadding: "0px",
                  slidesToShow: 1,
                },
              },
            ],
          });
        }
        this.getObservable();
      }, 1000);
    });
  }

  getObservable() {
    this.breakpointObserver
      .observe(["(min-width: 1025px) and (max-width: 1200px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          // this.app.record = 3;
        } else {
          // this.iframeDesign();
          // if(window.innerWidth < 1500){
          //   this.app.record = 4;
          // }
         
        }
      });
  }

  iframeDesign() {
    let slick = $(".videoSlider");
    let slickHeight = slick.height();
    let slickWidth = slick.width();
    let newWidth = slickWidth * 1;
    if (window.innerWidth < 768) {
      $('.frameVideo').css('height', slickHeight);
      $('.frameVideo').css('width', "100%");
      $('.offer-sec .col-md-8').css('background-color', 'transparent');
    } else {
      $('.frameVideo').css('height', slickHeight - 20);
      $('.frameVideo').css('width', newWidth - 60);
    }
  }

  toggleOffer : boolean = true;
  toggleOfferPlay(){
     this.toggleOffer = !this.toggleOffer;
  }

  toggleAutoplay() {
    this.isAutoplay = !this.isAutoplay;
  }

  reviewBanner() {
    $(document).ready(() => {
    let  $reviewSlider =   $(".reviewSlider");
      $(".reviewSlider").slick({
        infinite: true,
        autoplay: this.toggleOffer,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              dots: true,
            },
          },
        ],
      });

      $('#toggleOffer').on('click', (e : any) =>{
        this.toggleOffer = !this.toggleOffer;
        $reviewSlider.slick('slickSetOption', 'autoplay', this.toggleOffer, true);
        $(e.target).find('i').toggleClass('fa-play fa-pause');
    })
    });
  }

  slickReviews() {
    setTimeout(() => {
    let  $reviewSlider = $(".rsWrap").slick({
        infinite: true,
        autoplay: this.isAutoplay,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: "false",
      });
      $('#toggleAutoplay').on('click', (e : any) =>{
        this.isAutoplay = !this.isAutoplay;
        $reviewSlider.slick('slickSetOption', 'autoplay', this.isAutoplay, true);
        $(e.target).find('i').toggleClass('fa-play fa-pause');
    });
    // Set the initial icon
    $('#toggleAutoplay').find('i').addClass('fa-pause');
    }, 0);
  }

  ngAfterViewInit(): void {
    $(".grList").slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: true,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,

            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,

            dots: true,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,

            dots: true,
          },
        },
      ],
    });


    this.breakpointObserver
      .observe(["(max-width: 701px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          $(".mobielHomeSlider").slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
            ],
          });
          $(".mobileSlider").slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
            ],
          });
        }
      });
  }

  getYTUrl1() {
    if (!this.YTShown) {
      this.YTShown = true;
      return this.santizer.bypassSecurityTrustResourceUrl(
        "https://youtube.com/embed/6iZW7X86VcY?rel=0&origin=" +
          this.app.YTOriginUrl
      );
    }
    return false;
  }

  getYTUrl() {
    if (!this.YTShown) {
      this.YTShown = true;
      //change from youtube link to cloud video link
      return this.santizer.bypassSecurityTrustResourceUrl(
        "https://s3.ap-south-1.amazonaws.com/dellretailstores.in/Dell+Product+Videos/DellXPS_Millennio-SKY_Alt_27_16x9_Web_v2_updated_1920x1080.mp4"
      );
    }
    return false;
  }




  isPuase : boolean = true;
  playVideo(): void {
    this.isPuase = !this.isPuase;
      let  youtubeIframe = document.getElementById('iframeVideo') as any;
      if( youtubeIframe != null){
        let redPlayButton =youtubeIframe.contentWindow.document.getElementsByClassName('ytp-large-play-button')[0];
        redPlayButton.click();
      }
  }
}
