import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[focus]"
})
export class FocusDirective {

  @HostListener("focusin", ["$event"])
  onFocus(event: any) {
    if(window.innerWidth > 800) return;
    let button = document.getElementById("navbar-toggle") as HTMLButtonElement;
    if (button) {
        // if(button.classList.contains("active")){
            button.click();  
            event.target.focus();         
        // }
    }
  }
}
