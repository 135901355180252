<header class="mainHeader">
  <div class="container">
    <div class="hambuger">
      <button id="navbar-toggle" class="navbar-toggle" tabindex="0" role="button">
        <span class="sr-only">Hamburger Menu</span>
        <em>
          <span></span>
          <span></span>
          <span></span></em>
      </button>
    </div>

    <div class="dell-logo-section">
      <a [routerLink]="['/']" queryParamsHandling="merge" class="headerLogo">
        <img id="headerLogo" src="https://d2ocdqfo0e576d.cloudfront.net/assets/Layer_1.svg" alt="Dell" />
      </a>
    </div>
 
    <nav class="navbar primary-navigation">
      <ul class="navitems" role="menu">
        <ng-container *ngFor="let item of menuData; let i=index" [ngClass]="{'latest-offers': item.type === 'poly'}">
          <ng-container *ngIf="item.type === 'poly'; else temp">
            <li style="display: flex;
                flex-direction: column;" role="none">
              <a href="javascript:void" 
              type="link" class="menu-dropdown-{{i}} dropdown-toggle"
              id="menu-dropdown-{{i}}"
              type="button"
              aria-expanded="true"
              attr.aria-controls="{{item.name}}"
              role="menuitem" *ngIf="item.landingPageConfigs.length > 0"
                (mouseover)="showDropDown(i)" (mouseout)="hideDropDown(i)" (click)="openToggle(i)">{{item.name }} ▾
        
              </a>
              <ul class="dropdown-{{i}} dropdown-menu" [id]="item.name" attr.aria-controls="menu-dropdown-{{i}}" style="display: none;"
            
                (mouseover)="showDropDown(i)" (mouseout)="hideDropDown(i)">
                <li *ngFor="let l of item.landingPageConfigs" role="none">
                  <a  role="menuitem" type="link" href="javascript:void" (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:f0upsdc&fmt=3'); app.callAnchorElement(hcIdDeclaration(l), l.target ? true : false)">{{l.name}}
                  </a>
                </li>
              </ul>
            </li>
            <!-- landingPagePath -->
          </ng-container>
          <ng-template #temp>
            <li *ngFor="let l of item.landingPageConfigs" role="none">
              <a role="none"  type="link" *ngIf="l.name === 'Redeem Offers'" href="javascript:void" 
              (click)="app.addImagePixel( 'https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:f0upsdc&fmt=3'); app.callAnchorElement(hcIdDeclaration(l), l.target ? true : false)">{{l.name}}
          </a>
          <a role="none" type="link"  *ngIf="l.name === 'Service Centre'" href="javascript:void"
          (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:py4j391&fmt=3'); app.callAnchorElement(hcIdDeclaration(l), l.target ? true : false)">{{l.name}}
      </a>
        
            </li>
          </ng-template>

        </ng-container>
      </ul>

      <ul id="socialContact-57897" class="socialList socialList-1" role="menu">

        <p class="socIco">Connect us on</p>
        <li role="none" aria-labelledby="socialContact-57897">
          <a href="javascript:void" target="_blank" type="link" (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:bq829dr&fmt=3');
          app.callAnchorElement('https://www.facebook.com/dellindia/', true)">
            <em class="fbIcon"></em>
            <span class="sr-only">Facebook</span>
          </a>
        </li>
        <li  role="none" aria-labelledby="socialContact-57897">
          <a href="javascript:void" target="_blank" type="link" (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:bq829dr&fmt=3') ;app.callAnchorElement('https://www.instagram.com/dellindia/', true)">
            <em class="isIcon"></em>
            <span class="sr-only">Instagram</span>
          </a>
        </li>
        <li  role="none" aria-labelledby="socialContact-57897">
          <a  href="javascript:void" target="_blank" type="link" (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:bq829dr&fmt=3'); app.callAnchorElement('https://www.youtube.com/user/DELLINDIA', true)">
            <em class="ytIcon"></em>
            <span class="sr-only">Youtube</span>
          </a>
        </li>
      </ul>

    </nav>
    <div id="socialContact-57896" class="socialContact">
      <span *ngIf="isMobile; else isDesktop;">
        <br>
        <small>Connect with Store<em></em></small>
        <a href="javascript:void" class="ccNumber" type="link" focus (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:bq829dr&fmt=3'); app.callAnchorElement('tel:+919513631770')">+919513631770 
        </a>
      </span>
      <ng-template #isDesktop>
        <span>
          <small>Connect with Store<em></em></small>
          <a href="javascript:void" class="ccNumber" type="link" (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:bq829dr&fmt=3');
          app.callAnchorElement('tel:+919513631770')">+919513631770 
            
          </a>

        </span>
      </ng-template>
      <span>
        <small class="socIco">Connect with us on <em></em></small>
        <ul class="socialList" id="socialContact-57899">
          <li aria-labelledby="socialContact-57899">
            <a href="javascript:void" target="_blank" type="link" (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:bq829dr&fmt=3');
               app.callAnchorElement('https://www.facebook.com/dellindia/', true)">
              <em class="fbIcon"></em>
              <span class="sr-only">Facebook</span>
              
            </a>
          </li>
          <li aria-labelledby="socialContact-57899">
            <a href="javascript:void" target="_blank"  type="link" (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:bq829dr&fmt=3') ;app.callAnchorElement('https://www.instagram.com/dellindia/', true)">
              <em class="isIcon"></em>
              <span class="sr-only">Instagram</span>
              
            </a>
          </li>
          <li aria-labelledby="socialContact-57899">
            <a href="javascript:void" target="_blank" type="link" (click)="app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:bq829dr&fmt=3'); app.callAnchorElement('https://www.youtube.com/user/DELLINDIA', true)">
              <em class="ytIcon"></em>
              <span class="sr-only">Youtube</span>
              
            </a>
          </li>

        </ul>
      </span>
    </div>
  </div>
</header>