import { Directive, HostListener, Input } from '@angular/core';
interface ShareData {
  displayName: string;
  formattedAddress: string;
}
@Directive({
  selector: '[appShareButton]'
})
export class ShareButtonDirective {


  @Input() shareData!: ShareData;
  constructor() { }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const { displayName, formattedAddress } = this.shareData;
    this.sharemobiel(displayName, formattedAddress);
  }

  private sharemobiel(displayName: string, address: string) {
    if (navigator.share) {
      navigator.share({
        title: displayName,
        url: `${window.location.origin}/${address}`
      }).then(() => {
        console.log('Thanks for sharing!');
      })
        .catch(console.error);
    } else {
      alert('Sharing is not supported in this browser.');
    }
  }

}
