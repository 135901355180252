import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilitiesService } from '../utilities.service';
import { ApiService } from '../api.service';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { IframeService } from '../iframe-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-store-locator-form',
  templateUrl: './store-locator-form.component.html',
  styleUrls: ['./store-locator-form.component.scss']
})
export class StoreLocatorFormComponent implements OnInit {
  enquiryForm !: FormGroup;
  submitted : boolean = false;
  @Input()
  consentText : string = "";
   
  @Output() popUp  = new EventEmitter<boolean>();

  constructor(private fb : FormBuilder, public utils : UtilitiesService, 
    private iframeService : IframeService,public modalService : NgbModal,
    private api : ApiService, public app : AppComponent, private router : Router) { }

  ngOnInit(): void {
    let emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    this.enquiryForm = this.fb.group({
      name: ['', [Validators.required,Validators.minLength(2), Validators.pattern('^[^<>!1234567890]*$')]],
      email: ['', [Validators.pattern(emailPattern)]],
      mobileNo: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(10)]],
      pincode: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(6)]],
      query: [''],
      sourceName: ['STORELOCATORHOME'],
      consent: [false, Validators.requiredTrue]
    });
  }

  get myForm () {
   return this.enquiryForm.controls;
  }

  description = ''
  submitText ="Submit";
  onSubmit(event :any,content : any){
    this.submitted = true
  let lead =  {
      "email":this.enquiryForm.value.email,
      "mobileNo":this.enquiryForm.value.mobileNo,
      "name":this.enquiryForm.value.name,
      "pincode":this.enquiryForm.value.pincode,
      "query":this.enquiryForm.value.query,
      "sourceName":this.enquiryForm.value.sourceName,
      "consent":this.enquiryForm.value.consent
    }
    this.utils.loadPixelScript(this.enquiryForm.value.pincode,"");
    let sourceName = this.enquiryForm.value.sourceName
     if(this.enquiryForm.valid){
      console.log(this.enquiryForm);
      this.submitText = "Loading..."
      this.createIframe();
      this.api.storeLocatorPageLead(lead, sourceName, this.app.tenantId).subscribe(data =>{
        this.submitText = "Submit";
        if (data.status == "SUCCESS") {
          this.enquiryForm.reset();
          this.submitted = false;
          this.popup();
          this.router. navigate(['thankyou'], {
            queryParams: { username:  lead.name, thankYouPage  : 1 , leadId : data.msg},
            queryParamsHandling: 'merge',
          }); 
        } else {
          this.description = data.desc;
          this.modalService.open(content, { windowClass: "modalCls" })
        }
      },
      err =>{
        this.submitText = "Submit";
        this.submitted = false;
        console.log(err);
        
      })
     }else{
      this.utils.focusFirstInvalidField(this.enquiryForm);
     }
  }
  popup(){
    this.utils.focusOnFirstElement('get-in-touch');
    this.popUp.next(false);
  }
  createIframe(){
    this.iframeService.createIFrame('https://skylight.gotrackier.com/pixel?adid=6679ac5c66d0da3b6f494b95');
  }

}
