import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilitiesService } from '../utilities.service';
import { AppComponent } from '../app.component';
import { RequestLead } from '../components/models/requestLead';
import { ApiService } from '../api.service';
import { debounceTime } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router  } from '@angular/router';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss']
})
export class EnquiryFormComponent implements OnInit,AfterViewInit  {

  requestCallBackForm!: FormGroup;
  submitted: boolean = false;
  formattedDate : string = "";
  submitText : string = "Submit";
  productInterest :any[] = [];

  minDate = new Date();

  @Input()
  storDetails :any = null;
  @Input()
  consentText : string = "";

  @Input()
  storeId : string | null = null

   @Output() removeStoreDetails = new EventEmitter<any>();

  description : string = "";

  constructor(private fb : FormBuilder, 
    public utils : UtilitiesService, public app : AppComponent,
    private apiService : ApiService,
    private modalService : NgbModal,
    private route : Router
    ) { }
    ngAfterViewInit(){ 
      let eme =  document.getElementById("overlay")as HTMLDivElement;   
      if(eme){
        if(this.storDetails == null){
          eme.style.display = 'block';
        }else{
          eme.style.display = 'none';
        }
      }
     

    }
  ngOnInit(): void {
    let emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    this.requestCallBackForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[^<>!1234567890]*$')]],
      email: ['', [Validators.required,Validators.pattern(emailPattern)]],
      mobileNo: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(10)]],
      pincode: ['', [Validators.pattern("[0-9]*"), Validators.minLength(6)]],
      productOfInterest: [''],
      callDate: ['', Validators.required],
      callTime: ['', Validators.required],
      sourceName: ['Partner Page - Request Callback'],
      consent: [false, Validators.requiredTrue],
      storeId:[""]
    });
    this.minDate.setDate(this.minDate.getDate());

    this.apiService.getProductInterest().subscribe((data:any) => {
      this.productInterest = data;
    });
  }


  get requestForm(){
   return this.requestCallBackForm.controls;
  }

  onRequestCallSubmit(event: any, content: any) {
    event.preventDefault()
    this.submitted = true;
    this.requestCallBackForm.patchValue({
      "storeId":  this.storeId ? this.storeId : this.storDetails.id ,
      "sourceName": this.storeId ? this.app.sourceName1 :  this.app.sourceName2
    })
    

    const date = new Date(this.requestCallBackForm.value.callDate);
    this.formattedDate = this.utils.formatDate(date);
    if (!this.requestCallBackForm.valid) {
      this.utils.focusFirstInvalidField(this.requestCallBackForm);
      return;
    }
    let requestLead = new RequestLead(
      this.requestCallBackForm.value.name,
      this.requestCallBackForm.value.email,
      this.requestCallBackForm.value.mobileNo,
      this.requestCallBackForm.value.pincode,
      this.requestCallBackForm.value.productOfInterest,
      this.formattedDate,
      this.requestCallBackForm.value.callTime,
      this.requestCallBackForm.value.consent,
      this.requestCallBackForm.value.sourceName,
      this.requestCallBackForm.value.storeId,
    )

    if (this.requestCallBackForm.valid) {
      let sourceName = this.requestCallBackForm.value.sourceName;
      this.submitText = "Loading..."
      this.app.createIframe();
      this.apiService.saveRequestCallBackLead(requestLead, this.app.tenantId, sourceName)
      .pipe(
        debounceTime(700)
      ).subscribe(data => {
        this.submitText = "Submit"
        if (data.status == "SUCCESS") {
          this.close();
          this.submitted = false;
          let name = this.requestCallBackForm.value.name;
          this.removeStoreDetails.next(true);
          this.route.navigate(['thankyou'], 
          { queryParams: { username:  name, thankYouPage  : 1 },
          queryParamsHandling: 'merge'
        }); 
        } else {
          this.description = data.desc;
          this.modalService.open(content, { windowClass: "modalCls" })
        }
      }, err => {
        this.submitText = "Submit"
        this.submitted = false;
      })
    }
  }

  close(){

     let eme =  document.getElementById("overlay")as HTMLDivElement; 
     if(eme) eme.style.display = 'none';
  
    this.removeStoreDetails.next(false);
  }
}
