import { AfterViewInit, Component, HostListener } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/api.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  mainContent: any
  queryParam = "";
  menuData: any = []
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: ActivatedRoute,
    private route: Router,
    public app: AppComponent,
    private apiService: ApiService) {


    this.apiService.getlandingPageConfig().subscribe(data => {
      let ld: any[] = data as any[];
      this.getLandingPageConfig(ld);
    })

    this.apiService.getcategoryMaster().subscribe((data: any) => {
      this.menuData = (data && data.length > 0) ? data : this.menuData;
      this.menuData = this.menuData.sort((a: any, b: any) => a.order - b.order);
      const today = new Date();

      const filteredData = this.menuData.map((menu: any) => {
        return {
          ...menu,
          landingPageConfigs: menu.landingPageConfigs.filter((config: any) => {
            return config.active && this.dateChecks(config) && this.dateChecksStart(config);
          })
        };
      });
      this.menuData = filteredData
      console.log("sorted data = ", this.menuData);
    })


  }

  isMobile: boolean = false;
  landingPageConfig = [];
  ngAfterViewInit(): void {
    this.router.queryParamMap.subscribe(param => {
      console.log("KEY Length:" + param.keys.length);
      if (param.keys.length > 0) {
        this.queryParam = "?";
        let count = 0;
        param.keys.forEach(key => {
          count++;
          if (param.keys.length > count) {
            this.queryParam += key + "=" + param.get(key) + "&";
          }
          else if (param.keys.length == count) {
            this.queryParam += key + "=" + param.get(key);
          }
        })
      }
    });
    
    $(".navbar-toggle").click(function (e: any) {
      $("html, body").toggleClass("scrollHide");
      $(e.target).toggleClass("active");
      $(".navbar").toggleClass("showNavbar");
      $(".menuOverly").toggleClass("active");
    });
    this.breakpointObserver.observe([
      "(max-width: 786px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
   let toggle = document.getElementById("navbar-toggle");
   if(toggle){
    toggle.addEventListener("click",(e)=>{
      if(window.innerWidth > 800) return;
      const firstNavItem: HTMLElement | null = document.querySelector('.navbar ul li a');
      if (firstNavItem) {
        firstNavItem.focus();
      }
    })
   }
  
    
  }
  goToOffers() {
    this.route.navigate(['/offers/'], { queryParamsHandling: "merge" });
  }
  goHome() {
    this.route.navigate(["/"])
  }


  hcIdDeclaration(l: any): string {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('hcid');
    return l.hcid ? (param && param.length > 0 ? l.landingPagePath + "?hcid=" + param  :  l.landingPagePath) : l.landingPagePath;
  }

  newLaunches: any[] = []
  newOffers: any[] = [];
  getLandingPageConfig(lands: any[]) {
    this.newLaunches = lands.filter(l => l.category == "launches" && l.active == 1 && this.dateChecks(l) && this.dateChecksStart(l));
    this.newOffers = lands.filter(o => o.category == "offers" && o.active == 1 && this.dateChecks(o) && this.dateChecksStart(o));
  }

  dateChecks(o: any): boolean {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const endCompDate = new Date(o.endDate.split("T")[0]);
    if (endCompDate < today) {
      return false;
    } else {
      return true;
    }
  }

  dateChecksStart(o: any): boolean {
    const today = new Date();
    today.setDate(today.getDate() + 1)
    const startCompDate = new Date(o.startDate.split("T")[0]);
    if (startCompDate > today) {
      return false;
    } else {
      return true;
    }
  }

  openToggle(i: any) {
    let show = $('.dropdown-' + i).css('display');
    if (show == 'none') {
      $('.dropdown-' + i).css('display', 'flex')
    } else {
      $('.dropdown-' + i).css('display', 'none')
    }

  }

  preventDefault(event: Event) {
    event.preventDefault();
  }

  showDropDown(i: any) {
    if (window.innerWidth > 768) {
      $('.dropdown-' + i).css('display', 'flex')
    }

  }
  hideDropDown(i: any,) {
    if (window.innerWidth > 768) {
      $('.dropdown-' + i).css('display', 'none')
    }
  }

}
