<span class="menuOverly"></span>

<section class="mainBanner">
    <button id="bannerAutoPlay-1" (click)="bannerAutoPlayFun()"
    matTooltip="Auto play/pause for Main Banners!"
    aria-label="Button that displays a tooltip when focused or hovered over"
    class="toggle-button border-none" role="button" tabindex="0">
        <i [class.fa-play]="!bannerAutoPlay" [class.fa-pause]="bannerAutoPlay" class="fas"></i>
         <span class="sr-only">Auto scroll Play/Pause for Main Banners</span>
      </button>
    <div class="sliderParent">
        <section id="banner">
        </section>
        <button class="slick-prev btnPrev" role="button" tabindex="0"></button>
        <button class="slick-next btnNext" role="button" tabindex="0"></button>
    </div>
</section>
<section role="region" class="mainBanner1" aria-label="Carousel label">
<app-banner></app-banner>
</section>
<app-store-list *ngIf="storeInfo" [storeInfoDetail]="storeInfo" (openPopup)="handleOpenPopup($event)"></app-store-list>
<app-enquiry-form *ngIf="showPopup"[consentText]="consentText"[storeId]="storeId" (removeStoreDetails)="removeStoreDetails($event)"></app-enquiry-form>

