
<div class="container mbTopbanner" id="mbTopbanner">
    <div class="searchWrap">
        <div class="mainSearchWrapper row d-flex">
           <div class="search-icon-div col-3 col-md-2 d-flex gap-1 px-2">
            <img src="assets/dds-images/dds2_location.svg" alt="dds2_location" srcset="">
            <button class="locate-me" role="button" tabindex="0" on-click="locateMe($event, 'currentLocation')"> 
                    Locate Me
            </button>
           </div>
           <div class="input-search col-5 col-md-7 d-flex gap-2 px-0 justify-content-center">
            <img src="assets/dds-images/IconLeft.svg" alt="search icon" style="width: 16px;">
            <input id="dell-search" class="w-100" type="text" #search class="mtmInput" tabindex="0" placeholder="Search Dell locations" [value]="searchValue" (keyup)="enableFindStoreBtn1($event,search.value)" />
           </div>
           <div class="col-4 col-md-3 pr-3 d-flex justify-content-end">
            <button [ngClass]="[util.btnClass()]" class="w-70"
            tabindex="0"
            (click)="enableFindStoreBtn(search.value); app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:pu9jpe6&fmt=3')"
             type="button">Find Stores</button>
           </div>
        </div>
        <ng-container *ngIf="storeList && storeList.length > 0;">
          <ul id="myUL" role="menu" aria-label="Store list Drop Down" tabindex="0">
              <li role="menuitem" class="mnu-item" tabindex="0" *ngFor="let list of storeList; let i = index" (mousedown)="currentLocationStores(list)" 
              (keydown.enter)="currentLocationStores(list)">
                  <img src="assets/img/searchIcon.svg" alt="" style="padding: 0 8px 0 0;"> <span>{{ list.description }}</span>
              </li>
          </ul>
      </ng-container>
    </div>
</div>
<div class="container mobileP0" *ngIf="topbanner">
    <div class="popularCity">
      <h3 *ngIf="show">View Stores by City</h3>
      <div class="">
        <div class="popularCityslider">
          <ul class="plList bigarrow">
            <li *ngFor="let city of cities">
              <a href="#" (click)="locateMe($event, city.name)">
                <em [class]="city.class"></em>
                {{ city.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
