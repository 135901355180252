
<section class="mainBanner12 innerPage" *ngIf="!isThankYouPage">
    <div class="container" *ngIf="storDetails">
        <div class="detailPageHead" *ngIf="storDetails.displayName">
            <h1>{{storDetails.displayName | textSpliteByHyphenPipe:'-':
                0}}</h1>
            <div class="wrapLocationRating gap-3">
                <button class="d-flex gap-2" [ngClass]="utils.btnClass('primary', false)" type="button">
                    <span>
                        <img src="assets/dds-images/Icon copy.svg" alt="">
                    </span>
                    <span>{{storDetails.displayName | textSpliteByHyphenPipe:'-':
                        1}}</span>
                </button>
                <app-star-rating [storDetails]="storDetails"></app-star-rating>
            </div>
        </div>
    </div>
</section>
<div *ngIf="isThankYouPage" class="thank-you-main-div mt-5">
    <div class="thankYoumsg">
        <h3>Thank You!</h3>
        <h6 *ngIf="userName && userName.length <= 20">Thank you for your interest in Dell{{ userName &&
            userName.length > 0 ? ", ": "."}} {{userName}}{{ userName && userName.length > 0 ? ".": ""}}</h6>
        <div class="px-15" *ngIf="userName.length > 20" [innerHTML]="userName"></div>
        <p class="mt-2 mb-5 h2">Our Dell representative will contact you soon.</p>
    </div>
</div>
<section class="storeDetailWrap">
    <div class="container">
        <div class="backBtnwrap d-flex gap-3"  (click)="goBack()" *ngIf="!isThankYouPage">
            <img src="assets/dds-images/Icon-Color.svg" alt="" srcset="">
            <span class="backBtn"> Back to Store Listing </span>
        </div>
        <div class="storeDetail">

            <app-store-gallery *ngIf="storDetails" [storDetails]="storDetails"
                (requestCallBackWindow)="handleOpenPopup($event)"></app-store-gallery>
              <app-google-review [storDetails]="storDetails"></app-google-review>

            <div class="enquiry-forms" [ngClass]="!isThankYouPage? 'mt-10' : ''">
                <div class="enqueryForm" id="getInTouch" *ngIf="!isThankYouPage && !showRequestCallBack">
                    <div class="eqFwrap">
                        <h2 class="boldLIght towLineHead">
                            <strong><span class="f-32">Enquiry - Get A call</span></strong> Please fill in your details below and our
                            team
                            will get in touch with you.
                        </h2>
                        <form [formGroup]="enquiryForm">
                            <ul class="efListwrap">
                                <li>
                                    <div class="dds__form__field">
                                        <div id="text-input-container-808636159" class="dds__input-text__container">
                                            <label id="text-input-label-808636159" for="text-input-control-808636159"
                                                class="dds__label">Name</label>
                                            <div class="dds__input-text__wrapper">
                                                <input placeholder="Enter Name" type="text" class="dds__input-text"
                                                    name="text-input-control-name-808636159"
                                                    id="text-input-control-808636159" required="true"
                                                    formControlName="name"
                                                    autocomplete="name"
                                                    aria-labelledby="text-input-label-808636159 text-input-helper-808636159" />
                                                <div id="text-input-error-808636158"
                                                     aria-describedby="text-input-control-808636159"
                                                    *ngIf="submitted && myForm.name.errors?.minlength"
                                                    class="error">Name must be 2 characters long</div>
                                                <div id="text-input-error-808636153" class="error"
                                                     aria-describedby="text-input-control-808636159"
                                                    *ngIf="submitted && myForm.name.errors?.pattern">Please enter a
                                                    valid name</div>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div class="dds__form__field">
                                        <div id="text-input-container-808636160" class="dds__input-text__container">
                                            <label id="text-input-label-808636160" for="text-input-control-808636159"
                                                class="dds__label dds__label--required">Mobile</label>
                                            <div class="dds__input-text__wrapper">
                                                <input type="text" placeholder="+91" class="dds__input-text"
                                                    name="text-input-control-mobileNo-808636160"
                                                    id="text-input-control-808636160" required="true"
                                                    (input)="utils.maxLength($event, 10)" inputmode="numeric"
                                                    formControlName="mobileNo"
                                                    autocomplete="mobile"
                                                    aria-labelledby="text-input-label-808636160 text-input-helper-808636160" />
                                                <div id="text-input-error-808636160" 
                                                
                                                aria-describedby="text-input-control-808636160"
                                                class="error"
                                                    *ngIf="submitted && myForm.mobileNo.errors?.required">
                                                    Please enter your Mobile number</div>
                                                <div id="text-input-error-808636161"
                                                aria-describedby="text-input-control-808636160"
                                                    *ngIf="submitted && myForm.mobileNo.errors?.minlength"
                                                    class="error">Please enter 10 digit Mobile Number
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
  
                                    <div class="dds__form__field">
                                        <div id="text-input-container-808636160" class="dds__input-text__container">
                                            <label id="text-input-label-808636160" for="text-input-control-808636159"
                                                class="dds__label dds__label--required">Pincode</label>
                                            <div class="dds__input-text__wrapper">
                                                <input type="text" placeholder="Pincode" appOnlynumber
                                                    formControlName="pincode" maxlength="6"
                                                    autocomplete="pincode"
                                                    (input)="utils.maxLength($event, 10)" inputmode="numeric"
                                                    class="dds__input-text" name="text-input-control-pincode-808636160"
                                                    id="pincode-input-control-808636160" required="true"
                                                    aria-labelledby="text-input-label-808636160 text-input-helper-808636160" />
                                                <div id="text-input-error-808636160" class="error"
                                                aria-describedby="pincode-input-control-808636160"
                                                    *ngIf="submitted && myForm.pincode.errors?.required">
                                                    Please enter your Pincode</div>
                                                <div id="text-input-error-808636161"
                                                aria-describedby="pincode-input-control-808636160"
                                                    *ngIf="submitted && myForm.pincode.errors?.minlength"
                                                    class="error">Please enter 6 digit Pincode</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                
                                    <div class="dds__form__field">
                                        <div id="text-input-container-1" class="dds__input-text__container">
                                            <label id="text-input-label-1"
                                                for="text-input-control-pincode-19"
                                                class="dds__label">Email</label>
                                            <div class="dds__input-text__wrapper">
                                                <input type="text" placeholder="Enter email" 
                                                    formControlName="email" maxlength="100"
                                                    autocomplete="email"
                                                    class="dds__input-text"
                                                    name="text-input-control-pincode-1"
                                                    id="text-input-control-1" required="true"
                                                    aria-labelledby="text-input-label-1 text-input-helper-1" />
                                                <div id="text-input-error-1"
                                                   aria-describedby="text-input-control-1"
                                                    *ngIf="submitted && myForm.email.errors?.pattern"
                                                    class="error">
                                                    Please provide email in abc&#64;example.com</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <button type="button" class="w-100 btn-middle" [ngClass]="utils.btnClass('primary', false)"
                                        (click)="onSubmit($event,content);app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:031y6qj&fmt=3')">{{submitText}}
                                    </button>
                                    <small></small>
                                </li>

                            </ul>


                            <p class="disclaimer" [innerHTML]="consentText" [ngStyle]="{'font-size': '12px'}">
                            </p>
                            <div style="display: flow-root;">
                                <input type="checkbox" tabindex="0" id="checkbox-control-752143778"
                                    name="checkbox-control-name-752143778" class="dds__checkbox__input"
                                    formControlName="consent" class="checkbox1">
                                <span></span>
                                <span class="confrim-check"><sup></sup>I would also like to recieve a text message (SMS)
                                    with the location of the nearest store.</span>
                            </div>
                            <small class="consent-error" *ngIf="submitted && myForm.consent.errors?.required">
                                <sup>*</sup>Please provide your consent before submitting the form.
                            </small>
                        </form>
                    </div>
                    <div class="thankYoumsg" style="display: none">
                        <h3>Thank You</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus iaculis.
                        </p>
                        <a href="#" class="crossBtn"></a>
                    </div>
                </div>

             <app-enquiry-form
             *ngIf="showRequestCallBack && !isThankYouPage"
             [storDetails]="storDetails" [consentText]="consentText" (removeStoreDetails)="removeStoreDetails($event)"></app-enquiry-form>
            </div>

            <div class="sotreListwrap" *ngIf="storeImages && storeImages.length > 0">
                <h2 class="boldLIght towLineHead">
                    <strong><span class="f-32">Store</span></strong> The best way to buy the products you love.
                </h2>
                <ul class="storeList">
                    <li *ngFor="let store of storeImages" class="li">
                            <img [src]="store.src" alt="" style="margin-bottom: 15px;" />
                            <h2>{{ store.heading }}</h2>
                            <p>{{ store.text }}</p>
                            <span *ngIf="store.price" [innerHTML]="store.price"></span>
                            <div class="btnwrap">
                                <a (click)="app.getDataLayervalues(store, 'Get_in_touch_product')"
                                    [href]="getMobileNumber(storDetails,store.vfn)" [ngClass]="utils.btnClass('primary', false)"
                                    class="mt-2 w-100">Get in
                                    Touch</a>
                            </div>
                        </li>

                </ul>
                <div id="slider_dots" class="slider_dots">
                    <div *ngFor="let store of storeImages" class="slider_navigators"></div>
                </div>                
            </div>
            <app-faq *ngIf="!isThankYouPage"></app-faq>
        </div>
    </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="thankYoumsg">
        <button class="btn-close thankyou-btn-close" aria-label="Close" (click)="d('Cross click')"></button>
        <h3 class="modal-title" id="modal-basic-title">Thank You</h3>

    </div>
    <div class="modal-body text-center">
        <p><strong>{{description}}</strong></p>
    </div>
</ng-template>
