<!-- 
<div class="rating" *ngIf="storDetails && storDetails.reviews.length > 0">

    <div class="stars-rate">
        <div class="main-star" style="position: relative;">
            <span class="fa fa-star" [ngClass]="{'checked' : getRating() >= 1}"></span>
            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(getRating() > 0 && getRating() < 1)" [ngClass]="{'checked' : (getRating() > 0 && getRating() < 1) , 'fa-star-half' : (getRating() > 0 && getRating() < 1) }"></span>
        </div>
        <div class="main-star" style="position: relative;">
            <span class="fa fa-star"  [ngClass]="{'checked' : getRating() >= 2}"></span>
            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(getRating() > 1 && getRating() < 2)" [ngClass]="{'checked' : (getRating() > 1 && getRating() < 2) , 'fa-star-half' : (getRating() > 1 && getRating() < 2) }"></span>
        </div>
        <div class="main-star" style="position: relative;">
            <span class="fa fa-star"  [ngClass]="{'checked' : getRating() >= 3}"></span>
            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(getRating() > 2 && getRating() < 3)" [ngClass]="{'checked' : (getRating() > 2 && getRating() < 3) , 'fa-star-half' : (getRating() > 2 && getRating() < 3) }"></span>
        </div>
        <div class="main-star" style="position: relative;">
            <span class="fa fa-star"  [ngClass]="{'checked' : getRating() >= 4}"></span>
            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(getRating() > 3 && getRating() < 4)" [ngClass]="{'checked' : (getRating() > 3 && getRating() < 4) , 'fa-star-half' : (getRating() > 3 && getRating() < 4) }"></span>
         </div>
        <div class="main-star" style="position: relative;">
            <span class="fa fa-star" [ngClass]="{'checked' : getRating() == 5}"></span>
            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(getRating() > 4 && getRating() < 5)" [ngClass]="{'checked' : (getRating() > 4 && getRating() < 5) , 'fa-star-half' : (getRating() > 4 && getRating() < 5) }"></span>
        </div>
    </div>
    <label> <strong>{{getRating() }}</strong> Google Ratings </label>
</div> -->

<div class="rating" *ngIf="storDetails && storDetails.reviews.length > 0">

    <div class="stars-rate">
        <div class="main-star position-relative" *ngFor="let i of [1,2,3,4,5]">
          <img src="assets/dds-images/half-star.svg" *ngIf="getRating() < i; else fullStarTemplate" alt="half-star">
          <ng-template #fullStarTemplate>
            <img  src="assets/dds-images/full-star.svg" alt="half-star">
          </ng-template>
    
        </div>
    </div>
    <label class="mt-2 lb"> <strong>{{getRating() }}</strong> Google Ratings </label>
</div>




