<div class="faqWrap" style="margin-top: 15px;">
    <h2 class="boldLIght towLineHead">
        <strong><span class="f-32">FAQ's</span></strong>
    </h2>
    
    <div class="d-flex">
        <button role="button" [ngClass]="utils.btnClass('tertiary')" (click)="AllExpansion()">Expand All</button>
        <button role="button" [ngClass]="utils.btnClass('tertiary')" (click)="initializeExpansion()">Collapse All</button>
    </div>
    <ul class="faqAccordion">
        <li class="content-1 px-4" (click)="toggleAnswer(i)" [ngClass]="isExpanded[i] ?'expaned' : ''" *ngFor="let faq of faqs; let i = index"  role="region">
            <h3 role="button" [attr.aria-controls]="'answer-'+i" [attr.aria-expanded]="isExpanded[i]" [attr.aria-controls]="'answer-'+i">
                {{faq.title}}
                <img src="assets/dds-images/Icon.svg" class="icon" [ngStyle]="{'transform': isExpanded[i] ? 'rotate(0deg)' : 'rotate(180deg)'}" alt="Icon">
            </h3>
            <div class="answer" id="answer-{{i}}" *ngIf="isExpanded[i]">
                <p>
                    <span [innerHTML]="faq.description">
                    </span>
                </p>
            </div>
        </li>

    </ul>
</div>