import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
  selector: "[checkBoxRef]"
})
export class CheckBoxRefDirective {

  @HostListener("keydown", ["$event"])
  checkboxClicked() {
      const checkbox = document.getElementById("checkbox1") as HTMLInputElement;
      if (checkbox) {
        checkbox.click();
      }
  }
}
